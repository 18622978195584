import React  from "react"
import Sidebar from "react-sidebar"
import { IoMdMenu } from "react-icons/io"
import Header from "./header"
import DesktopSidebar from "./sidebar.js"
import {withStyles} from '@material-ui/core/styles'
import {Divider} from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckIcon from '@material-ui/icons/Check';
import {stripeProMonthlyCheckout} from '../helpers/stripe'
import {Progress} from './wigets/stripe-progress'
import Auth from "../Auth"

const auth = new Auth();

const color = "rgb(232, 87, 58)";

const useStyles = () => ({
    title:{
        width:'100%',
        textAlign:'center',
        marginTop: 50,
        marginBottom: 20,
        fontSize: 24,
        fontWeight: 'bold',
        color:color
    },
    planBoxWrapper:{
        minWidth:800, width:'100%', margin:'auto', height: 'min-content',
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
        '@media screen and (max-width:1142px)':{
            minWidth: 350,
            flexDirection:'column'
        }
    },
    planBox:{
        width:'100%', maxWidth: 400, float:'left',
        padding: 20,
    },
    planCard:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        padding: 10,
        boxShadow:'0 0 10px 1px #30303030',
        cursor:'pointer',
        borderRadius:'5px',
        '&:hover':{
            boxShadow:'0 0 20px 5px #3030307f',
        }
    },
    planCardSelected:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        padding: 10,
        boxShadow:'0 0 10px 2px #303030',
        cursor:'pointer',
        border:`solid 5px ${color}`,
        position:'relative',
    },
    interval:{
        fontSize: 24,
        fontWeight:'bold',
        color:'black',
        marginTop: 20,
        marginBottom: 20,
    },
    amount:{
        fontSize: 72,
        color:'grey',
        fontWeight:'bold'
    },
    dallorIcon:{
        fontSize: 40,
        color: 'black',
    },
    descirpton:{
        marginBottom: 20,
        fontSize: 20,
        textTransform:'uppercase'
    },
    total:{
        marginTop: 40,
        marginBottom:40,
        color:'gray',
        textAlign:'center',
        height: 50,
    },
    oldPay:{
        position:'relative'
    },
    line:{
      backgroundColor: '#000',
      display: 'inline-block',
      height: '1px',
      position: 'absolute',
      verticalAlign: 'middle',
      width: '70px',
      left: 40,
      top: 15,
    },
    continueButton:{
        backgroundColor: color,
        color:'white',
        border:'none',
        outline:'none',
        padding: 10,
        paddingRight: 40,
        paddingLeft: 40,
    },
    continue:{
        width: '100%',
        textAlign:'center',
        marginTop: 50
    }
})

const CheckBox = () =>{
    return (
        <div style={{width: 50, height: 50, backgroundColor:color,display:'flex', justifyContent:'center', alignItems:'center', 
        position:'absolute', right:0, bottom:0}}>
            <CheckIcon style={{color:'white', fontSize: 40}} />
        </div>
    )
}

class UpgradeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      isOpen: false,
      plan:'year',
      backDropOpen:false,
      user:null,
    }
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
    this.selectPlan = this.selectPlan.bind(this);
  }

  onSetSidebarOpen(open) {

    this.setState({ sidebarOpen: open })

  }

  selectPlan = (plan) =>{
    this.setState({plan: plan})
  }

  submit = () =>{
        this.setState({backDropOpen: true})
    if(this.state.user.email){
      stripeProMonthlyCheckout(this.state.plan, this.state.user.email)
    }
  }

  async componentDidMount () {
    const user = await  auth.user();
    this.setState({user: user})
  }

  render() {
      const {classes} = this.props;
    return (
      <Sidebar
        sidebar={<DesktopSidebar> </DesktopSidebar>}
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        styles={{ sidebar: { background: "white" } }}
      >
          <Progress open={this.state.backDropOpen} />
        <div className="sidebar-btn">
          <IoMdMenu size={24} onClick={() => this.onSetSidebarOpen(true)} />
        </div>

        <div className="d-flex">
          <div className="desktop-only">
            <DesktopSidebar> </DesktopSidebar>
          </div>
          <div className="" style={{ width: "100%" }}>
            <Header></Header>
            <div className="content-wrapper">
                <div className={classes.title}>Choose a Plan</div>
                <div className={classes.planBoxWrapper}>
                    <div className={classes.planBox} >
                        <div className={(this.state.plan === "month")?classes.planCardSelected:classes.planCard} onClick={()=>{this.selectPlan("month")}}>
                            <div className={classes.interval}>1 Month</div>
                            <div  className={classes.amount}> <AttachMoneyIcon   className={classes.dallorIcon} /><span>3.99</span></div>
                            <div  className={classes.descirpton}>per month</div>
                            <Divider/>
                            <div   className={classes.total}> $3.99 every month</div>
                            {(this.state.plan === "month") && <CheckBox />}
                        </div>
                    </div>
                    <div className={classes.planBox}>
                        <div className={(this.state.plan === "year")?classes.planCardSelected:classes.planCard}  onClick={()=>{this.selectPlan("year")}}>
                            <div className={classes.interval}>1 Year</div>
                            <div  className={classes.amount}> <AttachMoneyIcon   className={classes.dallorIcon} /><span>1.99</span></div>
                            <div  className={classes.descirpton}>per month</div>
                            <Divider/>
                            <div  className={classes.total}>
                                <div>Pay 
                                    <div className={classes.oldPay}>
                                      $48.00
                                      <div className={classes.line}></div>
                                    </div>
                                </div>
                                <span>$24.99 every year</span>
                            </div>
                            {(this.state.plan === "year") && <CheckBox />}
                        </div>
                    </div>
                </div>
                <div className={classes.continue}>
                    <button className={classes.continueButton} onClick={()=>{this.submit()}} >CONTINUE</button>
                </div>
            </div>
          </div>
        </div>
      </Sidebar>
    )
  }
}

export default withStyles(useStyles)(UpgradeComponent)
