import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe('pk_live_uAqzVkGIyvwHPCqM35a4dQSe');
// const stripePromise = loadStripe('pk_test_sVdNBB8fUsdjCPDveXzBkVJv');


export const stripeProMonthlyCheckout = async (plan, email) => {

  const planId = plan==="month"?'plan_HESRj5SI6aMFF8':'plan_HESRxiXzObiiRY'
    // const planId = plan==="month"?'plan_HEQ7lUQFpviamd':'plan_HEQ53DldxJszXN'

    const stripe = await stripePromise;

    stripe.redirectToCheckout({
        items: [{ plan: planId, quantity: 1 }],
        customerEmail:email,
        successUrl:`http://${window.location.host}/profile?success={CHECKOUT_SESSION_ID}`,
        cancelUrl: `http://${window.location.host}${window.location.pathname}`,
    })
    .then((res)=>{
        console.log(res)
    });
}


 